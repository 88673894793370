.dashboard2 {
    /* height: 100vh;
    width: 100vw; */
    display: flex;
    flex-direction: column;
    gap: .3rem;
    align-items: center;
    justify-content: start;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 3rem;

  }
  .dashead{
    padding: 1.3rem;
    background-color: #fff;
    color: #000;
    border: 1px solid black;
  }
 
  .RowHead{
    color: #fff;
    background-color: red;
  }
 
  
  .Row{
    background-color: #f0f0f0;
  }
  .rowEven{
    background-color: #fff;
  } 
  /* .RowHead2{
    color: #fff;
    background-color: orange;

  }
  .RowHead3{
    color: #fff;
    background-color: green;

  } */

  span{
    display: flex;
    flex-direction: row;
    gap:.3rem
  }
  .ButtonSection{
    display: flex;
    align-self: flex-end;
    flex-direction: row;
    gap: 3rem;
  
  }
  button{
    background-color: gray;
    color: #000;
  }  
  @media only screen and (max-width: 981px) {
    .RBody{
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 2rem;
    }
  }
