.dashboard2 {
    /* height: 100vh;
    width: 100vw; */
    display: flex;
    flex-direction: column;
    gap: .3rem;
    align-items: center;
    justify-content: start;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 3rem;

  }
  .dashead{
    padding: 1.3rem;
    background-color: #fff;
    color: #000;
  }
 
  .RowHead{
    color: #fff;
    background-color: #000;
    padding: 1.3rem;

  }
  .RowHeadw{
    color: #000;
    background-color: #fff;
    padding: .5rem;
    width: 20rem;

  }
  .CommonWid{
    width: 70%;
  }
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 1.3rem;
  }
  .Lowerfill{
    display: flex;
    flex-direction: column;
    border: 1px solid #000;
    width: 100%;
  }
  .FirstEntry{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 2rem;
  }
  .Row2{
    background-color: #fff;
    align-items: center;
  }
  .TabWid{
    width: 100%;
  }
  
  .rowEven{
    background-color: #fff;
  }
  .RowHead2{
    color: #fff;
    background-color: purple;

  }
  .RowHead3{
    color: #fff;
    background-color: green;

  }

  span{
    display: flex;
    flex-direction: row;
    gap:.3rem
  }
  .ButtonSection{
    display: flex;
    align-self: flex-end;
    flex-direction: row;
    gap: 3rem;
  
  }
  button{
    background-color: gray;
    color: #000;
  }  

  .rowSpecs {
    background-color: "#fff";
    border-left: 1px solid #d0d0d0;
    border-right: 1px solid #d0d0d0;
  }

  @media only screen and (max-width: 981px) {
    .RBody{
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 2rem;
    }
  }