.dashboard {
    /* height: 100vh;
    width: 100vw; */
    display: flex;
    flex-direction: column;
    gap: .3rem;
    align-items: center;
    justify-content: start;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 3rem;

  }
  .HeaderR2{
    display: flex;
    background-color: purple;
    height: 5rem;
    width: 100%;
    color: #fff;
    text-align: center;
    font-size: 3rem;
    font-weight: 600;
  }
  .RBody{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-self: baseline;
  }
  .HeaderSide{
    width: 25rem;
    height: 25rem;
  }
  .Caption{
    text-align: center;
  }
  table, th, td {
    /* border: 1px solid white; */
    border-collapse: collapse;
    border-bottom: 1px solid #a9a9a9;
  }
  table, tr{
    border-bottom: 1px solid #d9d9d9 !important;
  }
 
  .dasheadp{
    background-color: purple;
    color: #fff;
    padding: 1.3rem;
  }
  td{
  padding: .7rem;
   /* background-color: grey;
   color: #000; */
  }
  
  .CheckBox{
    height: 3.5rem;
    width: 3rem;
    border-radius: 20px;
  }
  .RigthAnswer{
    padding: 10px;
    border-radius: 2px;
  }
  .LeftAnswer{
    padding: 10px;
    border-radius: 2px;
  }
  .AnswerDisplay{
    background-color: #d0d0d0;
    border-radius: 2px;
    width: 20rem;
  }
  span{
    display: flex;
    flex-direction: row;
    gap:.3rem
  }
  .ButtonSection{
    display: flex;
    align-self: flex-end;
    flex-direction: row;
    gap: 3rem;
  
  }
  button{
    background-color: gray;
    color: #000;
  }  

  @media only screen and (max-width: 981px) {
    .RBody{
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 2rem;
    }
  }