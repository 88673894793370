.dashboard {
    /* height: 100vh;
    width: 100vw; */
    display: flex;
    flex-direction: column;
    gap: .3rem;
    align-items: center;
    justify-content: start;
    padding-left: 1rem;
    padding-right: 1rem;

  }
  .dashboard__container {
    display: flex;
    flex-direction: row;
    text-align: center;
    padding: 3rem;
  }
  .dashboard__btn {
    padding: .3rem;
    font-size: 18px;
    border: none;
    color: white;
    background-color: black;
  }
  .HeaderSection{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-content: center;
    background-color:#005EB8;
    padding-top: 1rem ;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .ImageHeader{
   width: 300px;
   height: 200px;
  }
  .QRImageHeader{
    width: 100px;
    height: 100px;
  }
  .RightHeader{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: large;
    color: #fff;
    align-items: center;
    font-weight: 700;

  }
  .SubHeading{
    font-size: 4rem;
    font-weight: 600;
    text-align: center;
  }
  .RBodyDImages{
    display: flex;
    flex-direction: row;
    gap: 10rem;
    align-self: flex-start;
    padding: 1.5rem;
  }
  .SideLabel{
    color: #fff;
    background-color: black;
    width: 15rem;
    height: 15rem;
    display: flex;
    border-radius: 20rem;
    align-items: center;
    justify-content: center;
   align-self: center;

  }
  .SmallHeading{
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
  }
  .selected_dashboard__btn{
    padding: .3rem;
    font-size: 18px;
    border: none;
    color: black;
    background-color: white;
  }
  span{
    padding: .4rem;
  }
  /* @media only screen and (max-width: 981px) {
    .RBodyDImages{
      display: flex;
      flex-direction: column;
      gap: 4rem;
      align-self: flex-start;
      padding: 1.5rem;
    }
  } */

  table, td, tr {
    border: 1px solid #d9d9d9 !important;
  }