.Machine{
    display: flex;
    align-self: baseline;
    width: 20rem;
    height: 10rem;
}
.HeaderSideLast{
    width: 50rem;  
}
@media only screen and (max-width: 981px) {
    .RBody{
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 2rem;
    }
  }