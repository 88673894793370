.dashboard {
    /* height: 100vh;*/
    /* display: flex;
    flex-direction: column; */
    gap: .3rem;
    align-items: center;
    justify-content: start;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 3rem;

  }
  .dashboard__container {
    display: flex;
    flex-direction: row;
    text-align: center;
    padding: 3rem;
  }
  .dashboard__btn {
    padding: .3rem;
    font-size: 18px;
    border: none;
    color: white;
    background-color: black;
  }
  .HeaderSection{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-content: center;
    background-color:#005EB8;
   
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .ImageHeader{
   width: 350px;
   height: 127px;
  }
  .QRImageHeader{
    width: 100px;
    height: 100px;
  }
  .ImageHeadereye{
    width: 250px;
   height: 147px;
  }
  .RightHeader{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: large;
    color: #fff;
    align-items: center;
    font-weight: 700;

  }
  .SubHeading{
    font-size: 4rem;
    font-weight: 600;
    text-align: center;
  }
  .SmallHeading{
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
  }
  .selected_dashboard__btn{
    padding: .3rem;
    font-size: 18px;
    border: none;
    color: black;
    background-color: white;
  }
  
  .formheading{
    display: flex;
    align-items: flex-start;
    font-weight: 700;
    font-size: 1.5rem;
  }
  .Input{
    resize: none;
    border-radius: .5rem;
    width: 20rem;
    height: 2rem;
    font-size: 1rem;
    padding-top: .7rem;
    padding-left: .9rem;
   

  }
  .TableForm{
    /* display: flex; */
    gap: 2rem;
    /* flex-direction: row;
    border: 1px solid #000; */
  }
  .headerTable{
    font-weight: 600;
  }
  .headingCloumn{
    display: flex;
  align-items: center;
  color: #fff;
  background-color: #000;
  border-radius: 5px;
  
  }
  .Columns{
    display: flex;
    flex-direction: column;
    border: 1px solid #000 ;
  }
  .TableStyle{
   display: flex;
    /* border: 1px solid #000; */
  }
  .Concent{
    display: flex;
    /* flex-direction: row; */
    border: 4px solid #0D6EFD;
    border-radius: 10px;
  }
  .ConcentHead{
    display: flex;
    /* padding: .4rem; */
    text-align: center !important;

  }
 
  .ConcentHead{
    /* background-color: blue; */
    text-align: center;
    align-items: center;
    /* border: 1px solid blue; */
    /* border-radius: 5px; */
  }
  .ConcentBox{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: .4rem;
    border-radius: 20px !important;
    /* border: 1px solid #000; */
  }
  .ConcentText{
    display: flex;
    background-color: #fff;
    /* text-align: center; */
    /* border: 1px solid #000; */
  }
  .TableInput{
    padding-top: .7rem;
    resize: none;
    border-radius: .5rem;
    font-size: 1rem;
    padding-bottom: .5rem;
  }
  .TableInputIn{
    padding-top: .7rem;
    width: 3rem;
    /* height: 1rem; */
    resize: none;
    /* font-size: 1rem; */
    padding-bottom: .5rem;
    border-radius: .5rem;
  }
  .headingCloumn2{
    display: flex;
    padding: .8rem;
    align-items: center;
    color: #fff;
    justify-content: center;
    border-radius: 5px;
  }
 
  /* .TableStyle{
    border: 1px solid #000;
  } */
  .headingCloumn{
    padding: .8rem;
    text-align: center;
    justify-content: center;
  }
  .ui{
    background-color: #fff;
  }
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  select{
    border: none;
  }
.ButtonSection{
  display: flex;
  align-self: flex-end;

}
button{
  background-color: gray;
  color: #000;
  padding: 1rem;
  border-radius: 2rem;
  width: 10rem;
}
@media only screen and (max-width: 981px) {
  /* .dashboard {
    overflow-x: auto;
  } */
  .Concent{
    flex-direction: column;
  }
  .ConcentBox{
    display: flex;
    justify-content: center;
  }
  .Columns{
    display: flex;
    flex-direction: column;
    border: 1px solid #000 ;
   
  }
  .dashboard {
    width: 120%;
  }
  .ConcentText{
    width: 20rem;
    padding: .4rem;
  }
  .TableForm{
    flex-direction: column;
  }
}